import React from "react"
import Layout from "../components/layout"
import HeadLabel from "../components/head-label"
import Seo from "../components/seo"

export default function Contact() {
  return (
    <Layout>
      <Seo
        title="Contact | 株式会社STARKS | スタークス"
        ogpSiteName="Contact | 株式会社STARKSコーポレートサイト"
      />

      <div className="container mx-auto">
        <div className="mx-auto mt-10 lg:w-1/2">
          <section className="text-gray-600">
            <div className="px-5 py-12 mx-auto">
              <HeadLabel label={"お問い合わせ"} />
              <div>
                お問い合わせのご返信は、担当者より折り返しご連絡させていただきます。
              </div>

              <div className=" mx-auto">
                <form
                  name="contact"
                  method="POST"
                  action="/thanks"
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                >
                  <input type="hidden" name="form-name" value="contact" />
                  <input type="hidden" name="bot-field" />

                  <div className="p-2 w-full">
                    <label
                      htmlFor="name"
                      className="leading-7 text-sm text-gray-600"
                    >
                      名前【必須】
                    </label>
                    <input
                      type="text"
                      id="name"
                      name="name"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                  <div className="p-2 w-full">
                    <label
                      htmlFor="email"
                      className="leading-7 text-sm text-gray-600"
                    >
                      メールアドレス【必須】
                    </label>
                    <input
                      type="email"
                      id="email"
                      name="email"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                      required
                    />
                  </div>
                  <div className="p-2 w-full">
                    <label
                      htmlFor="tel"
                      className="leading-7 text-sm text-gray-600"
                    >
                      電話番号（ハイフンなし）
                    </label>
                    <input
                      type="text"
                      id="tel"
                      name="tel"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"
                    />
                  </div>

                  <div className="p-2 w-full">
                    <label
                      htmlFor="message"
                      className="leading-7 text-sm text-gray-600"
                    >
                      問い合わせ内容【必須】
                    </label>
                    <textarea
                      id="message"
                      name="message"
                      className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-blue-500 focus:bg-white focus:ring-2 focus:ring-blue-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"
                      required
                    ></textarea>
                  </div>

                  <div className="p-2 w-full text-center">
                    <button
                      type="submit"
                      className="bg-[#3b5] border-0 mt-4 py-2 px-20 focus:outline-none hover:bg-[#1f7434] rounded-full mx-auto text-white  text-lg"
                    >
                      送信
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  )
}
